/**
 * 服务器
 */

import { requestRaw, ApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'

/**
 * 服务器接口
 */
export interface AppConfigs {

  id?: number;

  // 应用名称
  application?: string;

  // 简要表名称
  profile?: string;

  // lable
  label?: string;

  // key的名称
  key?: string;

  // key的值
  value?: string;

}

/**
 * 团队服务请求api接口
 */
class AppConfigsApi {
  public doPullDataList (pagerQueryBean: PagerQueryBean, baseUrl: string): Promise<PageDataApiResult<AppConfigs>> {
    return requestRaw<PageDataApiResult<AppConfigs>>({
      url: baseUrl + 'appconfigs/query',
      data: pagerQueryBean,
      method: 'POST'
    }, false)
  }

  public insertDataList (dataList: AppConfigs, baseUrl: string) {
    console.log(dataList)
    return requestRaw<PageDataApiResult<AppConfigs>>({
      url: baseUrl + 'appconfigs',
      data: dataList,
      method: 'PUT'
    }, false)
  }

  /**
   * 保存一条记录
   * @param item
   * @param baseUrl
   * @returns
   */
  public saveItem (item: AppConfigs, baseUrl: string) {
    return requestRaw<PageDataApiResult<AppConfigs>>({
      url: baseUrl + 'appconfigs/save',
      data: item,
      method: 'POST'
    }, false)
  }

  // public updateItemById () {}
  public deleteItemByIds (ids: number[], baseUrl: string): Promise<ApiResult> {
    return requestRaw<ApiResult>({
      url: baseUrl + 'appconfigs',
      data: ids,
      method: 'DELETE'
    }, false)
  }

  // 总线刷新
  public busRefreshConfigs (baseUrl: string) {
    return requestRaw<any>({
      url: baseUrl + 'actuator/busrefresh',
      method: 'POST'
    }, false)
  }

  // public getItemById () {}
}

export default new AppConfigsApi()
