
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import BaseTablePanel from '@/frame/share/table-panel'
import AppConfigsApi, { AppConfigs } from '@/api/maintain/app-configs'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'
import tableConfig from './config-detail-list.config'
import { Component, Watch } from 'vue-property-decorator'
import { getterCurAppProfile, getterCurInstanceIp } from '@/store'
import { ApplicationProfile } from '@/api/maintain/application-profile'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: [],
  application: undefined,
  label: undefined,
  key: undefined,
  value: undefined,
  profile: undefined
}

@Component({
  name: 'configDetailDataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class ConfigDetailDataList extends BaseTablePanel<AppConfigs> {
  get curProfile () {
    return getterCurAppProfile()
  }

  /// ip
  ip = ''
  /// 端口
  port = ''

  get baseUrl () {
    return `${location.protocol}//${this.ip}:${this.port}/api/`
  }

  get busNoticeUrl () {
    return `${location.protocol}//${this.ip}:${this.port}/`
  }

  loading = false
  showModal = false
  queryData: PagerQueryBean= _.cloneDeep(defaultQuery)
  pageData: PageListData<AppConfigs>={
    items: [],
    total: 0,
    pageSize: 10
  }

  // 手动输入的applicationName
  appNameInput?: string = ''

  /// 表单数据
  formData: AppConfigs={}

  model='new'

  tableConfig=tableConfig

  rules={
    key1: [
      { required: true, type: 'string', message: '配置key不能为空', trigger: 'blur' }
    ],
    value1: [
      { required: true, type: 'string', message: '配置值不能为空', trigger: 'blur' }
    ]
  }

  created () {
    this.queryData.application = this.curProfile.applicationCode
    this.queryData.profile = this.curProfile.profile
    this.ip = this.$route.params.ip
    this.port = this.$route.params.port

    this.doPullDataList()
  }

  /**
  * 从服务器拉起数据
  */
  onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<AppConfigs>> {
    return AppConfigsApi.doPullDataList(pagerQueryBean, this.baseUrl)
  }

  /// 拉取表格数据
  doPullDataList () {
    AppConfigsApi.doPullDataList(this.queryData, this.baseUrl).then((res) => {
      this.pageData.items = res.data?.items || []
    })
  }

  /// 返回上级
  pageBack () {
    this.$router.back()
    // this.$emit('pageBack', 'ConfigVersionDataList')
  }

  // 新增应用
  addConfig () {
    this.model = 'new'
    this.formData = {
      application: this.curProfile.applicationCode,
      profile: this.curProfile.profile
    }
    this.showModal = true
  }

  /// 编辑配置信息
  editInfo (val: any) {
    this.model = 'edit'
    this.formData = _.cloneDeep(val)
    this.showModal = true
  }

  /**
   * 根据id删除数据项
   * @param id
   */
  deleteItemById (id: number) {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要删除该数据吗？</p>',
      onOk: () => {
        AppConfigsApi.deleteItemByIds([id], this.baseUrl).then((res) => {
          this.$Notice.success({
            title: '成功',
            desc: '删除成功'
          })
          this.doPullDataList()
        }).catch((err) => {
          this.$Notice.error({
            title: '错误',
            desc: err.message
          })
        })
      }
    })
  }

  /// 保存配置
  saveConfig () {
    AppConfigsApi.saveItem(this.formData, this.baseUrl).then((res) => {
      this.$Notice.success({
        title: '成功',
        desc: this.model === 'new' ? '新增成功' : '保存成功'
      })
      this.showModal = false
      this.doPullDataList()
    }).catch((err) => {
      this.$Notice.success({
        title: '错误',
        desc: err.message
      })
      this.showModal = false
    })
  }

  @Watch('curProfile')
  watchCurProfile (newValue: ApplicationProfile) {
    this.queryData.application = newValue.applicationCode
    this.queryData.profile = newValue.profile
    this.ip = this.$route.params.ip
    this.port = this.$route.params.port

    this.doPullDataList()
  }

  /**
  * 表格刷新按钮点击事件
  * @param querybean
  */
  onTablePanelRefresh (querybean: PagerQueryBean) {
    this.doPullDataList()
  }
}
